<template>
  <div class="gonggaoxq">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item >企业服务</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="xq">
         <div class="title">{{datas.title}}</div>
        <div class="h1">
          <span>发布时间：{{datas.createTime}}</span>
          <span style="margin-left: 20px" v-if="datas.source">来源：{{datas.source}}</span>
        </div>
        <div v-html="datas.text">
        </div>
        <fujian style="margin-top: 50px" :files="datas.accessorySet" v-if="datas.accessorySet" :showtitle="true"></fujian>
      </div>
    </div>
  </div>
</template>

<script>
import {noticedetail} from "../../request/moudle/zcfw";
import fujian from '../common/fujian.vue'
import {filesub} from '../../utils/index'
export default {
  name: "gonggaoxq",
  components:{
    fujian
  },
  data(){
    return {
      filesub:filesub,
      datas:''
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    loadxq(){
      this.$api.zcfw.noticedetail({
        id:this.$route.query.id
      }).then((res)=>{
          this.datas=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .gonggaoxq{
     background: #F5F5F5;
    .fujian{
      margin-top: 50px;
      .as{
        line-height: 25px;
        color:  #409EFF;
      }
    }
    .header{
      height: 180px;
      background: url("../../assets/gxjy/gxjy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .xq{
        background: #fff;
        min-height: calc(100vh - 350px);
        margin-top: 20px;
        padding: 20px;
      }
      .title{
        color: #292929;
        text-align: center;
        font-weight: bolder;
        font-size: 20px;
      }
      .h1{
        line-height: 50px;
        text-align: center;
        span{
          margin-right: 50px;
        }
      }
    }
  }
</style>